	import { Col, Row } from '@themesberg/react-bootstrap';
	import React from 'react';
	import applicationFormService from '_services/beneficiaryServices/applicationFormService';
	import i18next from 'i18next';
	import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
	import { faFileAlt, faFilePdf, faFileWord, faFileImage } from '@fortawesome/free-solid-svg-icons';

	const urlPath = process.env.REACT_APP_WEB_API_BASE + '/applicationForm';

	class Attachment extends React.Component {
		state = {
			files: null
		};

		fileInputRef = React.createRef();

		componentDidMount() {
			if (this.props.applicationFormId) {
				applicationFormService.getFile(this.props.applicationFormId)
					.then((res) => {
						this.setState({ files: res.data });
					})
					.catch((error) => console.error(error));
			}
		}

		handleFileSelect = (event) => {
			const selectedFiles = Array.from(event.target.files);

			if (this.props.attachmentRef) {
				this.props.attachmentRef.current = selectedFiles;
			}

			// Automatically submit files if applicationFormId is available
			if (this.props.applicationFormId) {
				this.handleSubmit(this.props.attachmentRef);
			}
		};

		handleSubmit = (attachmentRef) => {
			const { applicationFormId } = this.props;
			if (applicationFormId && attachmentRef && attachmentRef.current) {
				applicationFormService.handleSubmitFile(applicationFormId, attachmentRef)
					.then(() => {
						this.props.attachmentRef.current = []; // Reset ref after submission

						// Clear the file input
						if (this.fileInputRef.current) {
							this.fileInputRef.current.value = "";
						}

						return applicationFormService.getFile(applicationFormId);
					})
					.then((res) => {
						this.setState({ files: res.data });
					})
					.catch((error) => {
						console.error("File submission failed:", error);
					});
			}
		};



		getFileIcon = (fileExt) => {
			switch (fileExt.toLowerCase()) {
				case 'pdf': return faFilePdf;
				case 'doc': case 'docx': case 'txt': return faFileWord;
				case 'png': case 'jpg': case 'jpeg': return faFileImage;
				default: return faFileAlt;
			}
		};

		downloadEmployeeData = (url, fileName, ext) => {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					const downloadUrl = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = downloadUrl;
					a.download = `${fileName}.${ext}`;
					a.click();
				});
		};

		render() {
			const { isFormEditDisabled } = this.props;
			const { files } = this.state;

			return (
				<>
					<Row className="align-items-center d-flex">
						<Col xs="auto">
							<div className="m-0">
								{i18next.t('applicationForm.attachFile')}
							</div>
						</Col>
						<Col xs="auto">
							<input
								id="filePicker"
								type="file"
								onChange={this.handleFileSelect}
								ref={this.fileInputRef}
								disabled={isFormEditDisabled}
								multiple
							/>
						</Col>
					</Row>
					{files && files.length > 0 && files.map((file, index) => (
						<Row className="m-1 align-items-center" key={file.id}>
							<Col xs="auto">
								<div className='m-1'>{i18next.t('applicationForm.attachedFile')} #{index + 1}</div>
							</Col>
							<Col xs="auto">
								<div
									className='m-1 btn btn-primary d-flex align-items-center'
									style={{padding: '8px 16px', borderRadius: '8px' }}
									onClick={() => this.downloadEmployeeData(
										`${urlPath}/getFile/${file.id}/${this.props.applicationFormId}`,
										file.fileName,
										file.fileExt
									)}
								>
									<FontAwesomeIcon icon={this.getFileIcon(file.fileExt)} className="mx-2" />
									<span>- {file.fileName}</span>
								</div>
							</Col>
						</Row>
					))}
				</>
			);
		}
	}

	export default Attachment;
